/*
*	Gebruiksaanwijzingen webshop
*
*	Toevoegen winkelmandje:
*	data-webshop-action="add-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal toe te voegen items]"

*	Verwijderen uit winkelmandje:
*	data-webshop-action="remove-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal te verwijderen items]"
*
*	Winkelmandje weergeven:
*	data-webshop-component="basket-amount"
*	data-webshop-component="basket-total"
*
*	data-webshop-component="basket-dropdown"
*
*		data-webshop-component="basket-dropdown-list"
*		-> Onder dit element een element met klasse "template-row" en "display:none" geplaatst
*			Dit element wordt dan gebruikt als basis voor product
*
*			Placeholders voor template-row:
*			- [[product_title]]
*			- [[product_amount]]
*			- [[product_total_price]]
*			- [[product_price]]
*			- [[product_id]]
*			- [[product_image_url]]
*
*/

$(document).ready(function ()
{
	//Knoppen initialiseren
	init_webshop_actions();

	//Werking checkout initialiseren
	init_checkout_billing();

	//Werking pop-up forms
	init_pop_up_actions();

	$('.pop_up_form').submit( function(e)
	{
		e.preventDefault();
		if( $(this).find('button.disabled').size() == 0 )
		{
			add_custom_product_to_basket($(this));
		}
	});

	//Winkelmandje ophalen
	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/get', function(result){
		fill_basket(result);
		show_count(result);
	});

	$(".ws-cart").on('click','.config-toggle', function () {
	    $header = $(this);
	    //getting the next element
	    $content = $header.next();
	    //open up the content needed - toggle the slide- if visible, slide up, if not slidedown.
	    $content.slideToggle(300, function () {
	        //execute this after slideToggle is done
	        //change text of header based on visibility of content div
	    });
	});

	$(".modal-body").on('click','.details-toggle', function () {
	    $header = $(this);
	    //getting the next element
	    $content = $header.next();
	    //open up the content needed - toggle the slide- if visible, slide up, if not slidedown.
	    $content.slideToggle(300, function () {
	        //execute this after slideToggle is done
	        //change text of header based on visibility of content div
	    });
	});

	$('[data-webshop_action="set-product-amount"]').change(setProductAmount);

});

function init_checkout_billing(){
	if ($('#billing-address-same').length > 0) {
		$billing_check = $('#billing-address-same');

		if ($billing_check.is(':checked')) {
			$('#ws-billing-address').hide();
		}

		$billing_check.change( function(){
			if ($billing_check.is(':checked')) {
				$('#ws-billing-address').slideUp(150);
			} else {
				$('#ws-billing-address').slideDown(150);
			}
		});
	}
}

function init_webshop_actions(){
	$('[data-webshop-action="add-item"]').off('click', add_product_to_basket);
	$('[data-webshop-action="add-item"]').on('click', add_product_to_basket);
}

function show_count(basket){
	var product_count = 0;
	if (Object.prototype.toString.call( basket.products ) === '[object Object]') {
		$.each(basket.products, function(id, product){
			product_count++;
		});
	}

	if( product_count < 1 )
	{
		$('[data-webshop-element="order-btn"]').hide();
		$('[data-webshop-element="no-products-message"]').show();
	}
	else
	{
		$('[data-webshop-element="order-btn"]').show();
		$('[data-webshop-element="no-products-message"]').hide();
	}

	$('[data-webshop-component="basket-amount"]').html(product_count);
}

function fill_basket(basket){
	$('[data-webshop-component="basket-dropdown"]').each(function(index, el){
		$(el).find('[data-webshop-component="basket-dropdown-list"]').html('');
		var product_count = 0;
		var total_price = 0;
		if (Object.prototype.toString.call( basket.products ) === '[object Object]') {
			$.each(basket.products, function(id, product){
				$template = $(el).find('.template-row').clone();
				$template.removeClass('template-row');

				$template.html(replaceAll($template.html(),'[[product_title]]', product.item.item_title));
				$template.html(replaceAll($template.html(),'[[product_url]]', product.item.url));
				var product_price = parseFloat(product.item.field_price.value);
				var product_total_price = product_price * parseFloat(product.amount);
				$template.html(replaceAll($template.html(),'[[product_total_price]]', product_total_price.toFixed(4) ));
				$template.html(replaceAll($template.html(),'[[product_price]]', product_price.toFixed(4) ));
				$template.html(replaceAll($template.html(),'[[product_price_m]]', ( product.item.field_unit.value == 'm' ? 'm' : '' )  ));
				$template.html(replaceAll($template.html(),'[[product_amount]]', product.amount));
				$template.find('[data-webshop-action="change-amount"]').first().attr('value',product.amount);
				$template.html(replaceAll($template.html(),'[[product_id]]', id));

				/* CUSTOM CODE */
				if( product.customdata != undefined && product.customdata.custom != 'cut' )
				{
					$.each(product.customdata, function(title, value )
					{
						$template.find('.config ul').append('<li><strong>'+title+':</strong> '+value+'</li>');
					});
				}
				else{
					$template.find('.config').addClass('hidden');
				}
				/* END - CUSTOM CODE */

				total_price += product_total_price;

				var image_url = "/placeholder/500x500";

				var image_file = '/upload/article/'+product.item.field_product_code.value+'.jpg'
				if( imageExists(image_file) )
				{
					image_url = image_file;
				}

				$template.html(replaceAll($template.html(),'[[product_image_url]]', image_url));

				$template.find('[data-webshop-action="remove-item"]').on('click', remove_product_from_basket);
				$template.find('[data-webshop-action="change-amount"]').on('change', change_product_amount);

				$template.css('display', '');

				$(el).find('[data-webshop-component="basket-dropdown-list"]').append($template);
				product_count++;
			});

			$('[data-webshop-component="basket-total"]').html( parseFloat(total_price).toFixed(4) );
			$('[data-webshop-component="basket-amount"]').html(product_count);
		}

	});

	show_count(basket);
	init_webshop_actions();
}

function change_product_amount(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).val();

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/update/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}
function setProductAmount()
{
	var val = $(this).val();
	$(this).next().attr('data-item-amount', val);
}
function add_product_to_basket()
{
	var itemid = $(this).attr('data-item-id');
	var title = $(this).attr('data-item-title');
	var product_code = $(this).attr('data-item-productcode');
	var amount = $(this).attr('data-item-amount');
	var pop_up = $(this).attr('data-pop-up');

	var modal_id = '';

	// Optreksystemen
	if( pop_up == 'lifting' )
	{
		modal_id = 'modal-lifting';

		$('#modal-lifting input#itemid').val(itemid);
		$('#modal-lifting').modal();
		$('#modal-lifting').on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-lifting .modal-header span').html(title);
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-lifting #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 29;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-lifting #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'torino' )
	{
		modal_id = 'modal-torino';

		$('#modal-torino input#itemid').val(itemid);
		$('#modal-torino').modal();
		$('#modal-torino').on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-torino .modal-header span').html(title);
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-torino #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 29;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-torino #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'genova' )
	{
		modal_id = 'modal-genova';

		$('#modal-genova input#itemid').val(itemid);
		$('#modal-genova').modal();
		$('#modal-genova').on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-genova .modal-header span').html(title);
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-genova #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 29;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-genova #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'japan' )
	{
		modal_id = 'modal-japan';

		$('#modal-japan input#itemid').val(itemid);
		$('#modal-japan').modal();
		$('#modal-japan').on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-japan .modal-header span').html(title);

			// WIDTH
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-japan #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 29;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-japan #width').attr('min', min_width);

			// PANELS
			var panels = product_code.substr(product_code.length - 6, 1);
			$('#modal-japan #panels').val( panels );
			$('#modal-japan #tracks').val( panels );
		});
	}
	else if( pop_up == 'cks' )
	{
		modal_id = 'modal-'+pop_up;

		$('#modal-'+pop_up+' input#itemid').val(itemid);
		$('#modal-'+pop_up).modal();
		$('#modal-'+pop_up).on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-'+pop_up+' .modal-header span').html(title);

			// WIDTH
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-'+pop_up+' #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 10;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-'+pop_up+' #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'shuttle' || pop_up == 'fms')
	{
		modal_id = 'modal-'+pop_up;

		$('#modal-'+pop_up+' input#itemid').val(itemid);
		$('#modal-'+pop_up).modal();
		$('#modal-'+pop_up).on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-'+pop_up+' .modal-header span').html(title);

			// WIDTH
			var max_width = product_code.substr(product_code.length - 4);
			$('#modal-'+pop_up+' #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 55;
			}
			else{
				min_width = max_width - 99;
			}
			$('#modal-'+pop_up+' #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'bcs' )
	{
		modal_id = 'modal-'+pop_up;

		$('#modal-'+pop_up+' input#itemid').val(itemid);
		$('#modal-'+pop_up).modal();
		$('#modal-'+pop_up).on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-'+pop_up+' .modal-header span').html(title);

			// WIDTH
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-'+pop_up+' #width').attr('max', max_width);
			if( max_width == 70 ){
				min_width = 55;
			}
			else if( max_width == 110 ){
				min_width = 71;
			}
			else if( max_width == 135 ){
				min_width = 111;
			}
			else if( max_width == 165 ){
				min_width = 136;
			}
			else if( max_width == 190 ){
				min_width = 166;
			}
			else if( max_width == 215 ){
				min_width = 191;
			}
			else if( max_width == 240 ){
				min_width = 216;
			}
			else if( max_width == 265 ){
				min_width = 241;
			}
			else if( max_width == 315 ){
				min_width = 266;
			}
			else if( max_width == 340 ){
				min_width = 316;
			}
			else if( max_width == 365 ){
				min_width = 341;
			}
			else if( max_width == 400 ){
				min_width = 366;
			}
			else if( max_width == 465 ){
				min_width = 401;
			}
			else if( max_width == 490 ){
				min_width = 466;
			}
			else if( max_width == 515 ){
				min_width = 491;
			}
			else if( max_width == 580 ){
				min_width = 516;
			}
			else{
				min_width = max_width - 24;
			}
			$('#modal-'+pop_up+' #width').attr('min', min_width);

			// CYLINDERS AMOUNT
			var cylinders_amount = getCylinderAmount( min_width );

			$('#modal-'+pop_up+' [data-pop-up="cylinders_amount_standard"]').html(cylinders_amount);
			$('#modal-'+pop_up+' #cylinders_amount').attr('min', cylinders_amount);
		});
	}
	else if( pop_up == 'cut-compleet' )
	{
		modal_id = 'modal-'+pop_up;

		$('#modal-'+pop_up+' input#itemid').val(itemid);
		$('#modal-'+pop_up).modal();
		$('#modal-'+pop_up).on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-'+pop_up+' .modal-header span').html(title);

			// WIDTH
			var max_width = product_code.substr(product_code.length - 3);
			$('#modal-'+pop_up+' #width').attr('max', max_width);
			if( max_width == 100 ){
				min_width = 10;
			}
			else{
				min_width = max_width - 49;
			}
			$('#modal-'+pop_up+' #width').attr('min', min_width);
		});
	}
	else if( pop_up == 'cut' )
	{
		modal_id = 'modal-'+pop_up;

		var unit = $(this).attr('data-unit');
		var package = $(this).attr('data-package');

		$('#modal-cut input#itemid').val(itemid);
		$('#modal-cut input#unit').val(unit);
		$('#modal-cut input#package').val(package);
		$('#modal-cut [data-pop-up="unit"]').html(unit);
		$('#modal-cut [data-pop-up="package"]').html(package);
		$('#modal-cut').modal();
		$('#modal-cut').on('shown.bs.modal', function (e) {
			$(this).find('form[data-toggle=validator]').validator('destroy');
			$(this).find('form[data-toggle=validator]').validator();
			$('#modal-cut .modal-header span').html(title);
		});
	}
	else
	{
		message = 'Product is toegevoegd aan winkelmandje!';
		if( lang.toLowerCase() == 'fr' ) message = 'Produit est ajouté au panier';

		$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/add/'+itemid+'/'+amount, function(result){
			fill_basket(result);
			new Noty({
				type: 'alert',
				layout: 'bottomRight',
				theme: 'sunset',
				text: message,
				timeout: 3000,
				progressBar: true,
				closeWith: ['click', 'button'],
				animation: {
					open: 'noty_effects_open',
					close: 'noty_effects_close'
				},
				queue: 'global',
				modal: false
			}).show();
		});
	}

	// fill_customdata if filled in data attributest (re-order)

	for( attr in $(this).data() )
	{
		if( attr.indexOf("customdata_") === 0 )
		{
			field = attr.replace("customdata_", "");

			attr_value = $(this).attr('data-customdata_'+field);

			$('#'+modal_id+' #'+field).val(attr_value);
		}
	}
	$('#'+modal_id+' [data-pop-up]').change();

}

function remove_product_from_basket(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).attr('data-item-amount');

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/remove/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function escapeRegExp(str) {
	if (typeof str != 'undefined') {
		return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}else{
		return '';
	}
}

function replaceAll(str, find, replace) {
	if (typeof str != 'undefined') {
		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	}else{
		return '';
	}
}

/* pop-up */
function init_pop_up_actions(){
	$('#modal-lifting [data-pop-up]').on('change', function()
	{
		calc_values_lifting();
	});
	$('#modal-torino [data-pop-up]').on('change', function()
	{
		calc_values_torino();
	});
	$('#modal-genova [data-pop-up]').on('change', function()
	{
		calc_values_genova();
	});
	$('#modal-japan [data-pop-up]').on('change', function(e)
	{
		calc_values_japan(e);
	});
	$('#modal-cks [data-pop-up]').on('change', function()
	{
		calc_values_cks();
	});
	$('#modal-bcs [data-pop-up]').on('change', function()
	{
		calc_values_bcs();
	});
	$('#modal-cut [data-pop-up]').on('change, keyup', function()
	{
		calc_values_cut();
	});
}

function calc_values_lifting(){
	var $modal = $('#modal-lifting')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());
	var height = parseFloat($modal.find('[data-pop-up="height"]').first().val());

	var width_underbar_select = $modal.find('[data-pop-up="width_underbar_select"]').first().val();
	var type_underbar = $modal.find('[data-pop-up="type_underbar"]').first().val();

	var type_tube = $modal.find('[data-pop-up="type_tube"]').first().val();
	var width_tubes_select = $modal.find('[data-pop-up="width_tubes_select"]').first().val();


	// profile
	var profile_width = width - 1.5;
	profile_width = round_decimal( profile_width, 1 );
	$modal.find('#profile').first().val(profile_width);

	// alu_bar
	var alu_bar = width - 4.8;
	alu_bar = round_decimal( alu_bar, 1 );
	$modal.find('#alu_bar').first().val(alu_bar);

	// width_underbar
	var underbar_yes_no = 0;
	if( type_underbar != 'Geen' )
	{
		underbar_yes_no = 1;
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().show();
	}
	else{
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().hide();
	}
	var width_underbar = (width-width_underbar_select)*underbar_yes_no;
	width_underbar = round_decimal( width_underbar, 1 );
	$modal.find('#width_underbar').first().val(width_underbar);

	// rope
	var rope = height + 25;
	$modal.find('#rope').first().val(rope);

	// pulleys
	var pulleys = width/40;
	if( pulleys < 2 ) pulleys = 2;
	pulleys = Math.floor( pulleys )
	$modal.find('#pulleys').first().val(pulleys);

	// tubes
	if( type_tube == 'Geen' )
	{
		tubes = 0;
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().hide();
		$modal.find('#width_tubes').first().parent().hide();
	}
	else{
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().show();
		$modal.find('#width_tubes').first().parent().show();
		tubes = (height/25)-1
	}
	tubes = Math.ceil( tubes );
	$modal.find('#tubes').first().val(tubes);

	// width_tubes
	var width_tubes = width-width_tubes_select;
	width_tubes = round_decimal( width_tubes, 1 );
	$modal.find('#width_tubes').first().val(width_tubes);

	// plexi_ring
	var plexi_ring = pulleys*tubes;
	$modal.find('#plexi_ring').first().val(plexi_ring);

	// plexi_ring
	$modal.find('#plexi_pear').first().val(pulleys);

	// support
	var support = width/70;
	if( support < 2 ) support = 2;
	support = Math.ceil( support )
	$modal.find('#support').first().val(support);

}

function calc_values_torino(){
	var $modal = $('#modal-torino')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());
	var height = parseFloat($modal.find('[data-pop-up="height"]').first().val());

	var width_underbar_select = $modal.find('[data-pop-up="width_underbar_select"]').first().val();
	var type_underbar = $modal.find('[data-pop-up="type_underbar"]').first().val();

	var type_tube = $modal.find('[data-pop-up="type_tube"]').first().val();
	var width_tubes_select = $modal.find('[data-pop-up="width_tubes_select"]').first().val();

	// width_underbar
	var underbar_yes_no = 0;
	if( type_underbar != 'Geen' )
	{
		underbar_yes_no = 1;
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().show();
	}
	else{
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().hide();
	}
	var width_underbar = (width-width_underbar_select)*underbar_yes_no;
	width_underbar = round_decimal( width_underbar, 1 );
	$modal.find('#width_underbar').first().val(width_underbar);

	// Koordoog
	var cord_eye = 2*Math.ceil(width/100);
	$modal.find('#cord_eye').first().val(cord_eye);

	// rope
	var cord_length = ((width*cord_eye)+(height*cord_eye)+(width*cord_eye))/100;
	$modal.find('#cord_length').first().val(cord_length);

	// tubes
	if( type_tube == 'Geen' )
	{
		tubes = 0;
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().hide();
		$modal.find('#width_tubes').first().parent().hide();
	}
	else{
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().show();
		$modal.find('#width_tubes').first().parent().show();
		tubes = (height/25)-1
	}
	tubes = Math.ceil( tubes );
	$modal.find('#tubes').first().val(tubes);

	// width_tubes
	var width_tubes = width-width_tubes_select;
	width_tubes = round_decimal( width_tubes, 1 );
	$modal.find('#width_tubes').first().val(width_tubes);


	// support
	var support = width/50;
	if( support < 2 ) support = 2;
	support = Math.ceil( support )
	$modal.find('#support').first().val(support);

}

function calc_values_genova(){
	var $modal = $('#modal-genova')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());
	var height = parseFloat($modal.find('[data-pop-up="height"]').first().val());

	var width_underbar_select = $modal.find('[data-pop-up="width_underbar_select"]').first().val();
	var type_underbar = $modal.find('[data-pop-up="type_underbar"]').first().val();

	var type_tube = $modal.find('[data-pop-up="type_tube"]').first().val();
	var width_tubes_select = $modal.find('[data-pop-up="width_tubes_select"]').first().val();

	// width_underbar
	var underbar_yes_no = 0;
	if( type_underbar != 'Geen' )
	{
		underbar_yes_no = 1;
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().show();
	}
	else{
		$modal.find('[data-pop-up="width_underbar_select"]').first().parent().hide();
	}
	var width_underbar = (width-width_underbar_select)*underbar_yes_no;
	width_underbar = round_decimal( width_underbar, 1 );
	$modal.find('#width_underbar').first().val(width_underbar);

	// tubes
	if( type_tube == 'Geen' )
	{
		tubes = 0;
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().hide();
		$modal.find('#width_tubes').first().parent().hide();
	}
	else{
		$modal.find('[data-pop-up="width_tubes_select"]').first().parent().show();
		$modal.find('#width_tubes').first().parent().show();
		tubes = (height/25)-1
	}
	tubes = Math.ceil( tubes );
	$modal.find('#tubes').first().val(tubes);

	// width_tubes
	var width_tubes = width-width_tubes_select;
	width_tubes = round_decimal( width_tubes, 1 );
	$modal.find('#width_tubes').first().val(width_tubes);


	// support
	var support = width/50;
	if( support < 2 ) support = 2;
	support = Math.ceil( support )
	$modal.find('#support').first().val(support);

}

function calc_values_japan(e){
	var $modal = $('#modal-japan')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());
	var height = parseFloat($modal.find('[data-pop-up="height"]').first().val());

	var panels = tracks = parseFloat($modal.find('[data-pop-up="tracks"]').first().val());

	var control_type = $modal.find('[data-pop-up="control_type"]').first().val();

	var hang_height = parseFloat($modal.find('[data-pop-up="hang_height"]').first().val());
	var chainweight = $modal.find('[data-pop-up="chainweight"]').first().val();

	// [breedte complete rail] + ( [3] x [aantal sporen – 1] ) / [aantal sporen]
	if( $(e.target).attr('id') == 'panel_width' )
	{
		var panel_width = $modal.find('[data-pop-up="panel_width"]').first().val();
	}
	else{
		var panel_width = ( width + ( 3 * (tracks-1) ) ) / tracks;
		panel_width = round_decimal( panel_width, 1 );
		$modal.find('#panel_width').first().val(panel_width);
	}


	var width_underbar = panel_width-1;
	width_underbar = round_decimal( width_underbar, 1 );
	var type_underbar = $modal.find('[data-pop-up="type_underbar"]').first().val();
	if( type_underbar == 'Geen' ) width_underbar = 0;
	$modal.find('#width_underbar').first().val(width_underbar);

	if( control_type == 'koord')
	{
		$modal.find('[data-pop-up="hang_height"]').first().parent().show();
		$modal.find('#cord_length').first().parent().show();
		$modal.find('#chainweight').first().parent().show();

		if( chainweight == 'Nee')
		{
			cord_length = ( width + hang_height ) * 2;
		}
		else
		{
			cord_length = ( width + hang_height - 8 ) * 2;
		}

		cord_length = round_decimal( cord_length, 1 );
		$modal.find('#cord_length').first().val(cord_length);
	}
	else
	{
		$modal.find('[data-pop-up="hang_height"]').first().parent().hide();
		$modal.find('#cord_length').first().parent().hide();
		$modal.find('#chainweight').first().parent().hide();
	}

	// support
	var support = width/70;
	if( support < 2 ) support = 2;
	support = Math.ceil( support )
	$modal.find('#support').first().val(support);
}

function calc_values_cks(){
	var $modal = $('#modal-cks')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());
	var hang_height = parseFloat($modal.find('[data-pop-up="hang_height"]').first().val());
	var chainweight =$modal.find('[data-pop-up="chainweight"]').first().val();

	if( chainweight == 'Nee')
	{
		cord_length = ( width + hang_height ) * 2;
	}
	else
	{
		cord_length = ( width + hang_height - 8 ) * 2;
	}

	cord_length = round_decimal( cord_length, 1 );
	$modal.find('#cord_length').first().val(cord_length);

	// support
	var support = width/70;
	if( support < 2 ) support = 2;
	support = Math.ceil( support )
	$modal.find('#support').first().val(support);
}

function calc_values_bcs(){
	var $modal = $('#modal-bcs')

	var width = parseFloat($modal.find('[data-pop-up="width"]').first().val());

	// CYLINDERS AMOUNT
	var cylinders_amount = getCylinderAmount( width );
	$modal.find('[data-pop-up="cylinders_amount_standard"]').html(cylinders_amount);
	$modal.find('#cylinders_amount').attr('min', cylinders_amount);
}

function calc_values_cut(){
	var $modal = $('#modal-cut')

	var unit = $modal.find('input#unit').first().val();
	var package = parseFloat($modal.find('input#package').first().val());
	var amount = parseFloat($modal.find('[data-pop-up="amount"]').first().val());

	$modal.find('.cut-message').first().hide();

	if( isNaN(amount)  || (unit != 'm' && package <= amount) ) return true;

	if( round_decimal(amount%package,5) != 0 )
	{
		$modal.find('.cut-message').first().show();
	}

}

function add_custom_product_to_basket($form)
{
	var values = $form.serialize();

	var itemid = $form.find('#itemid').val();

	$.ajax({
		url: '/api/v2/'+site_id+'/'+lang+'/webshop/basket',
		method: "POST",
		data: values,
	})
	.done(function( result ) {
		fill_basket(result);

		message = 'Product is toegevoegd aan winkelmandje!';
		if( lang.toLowerCase() == 'fr' ) message = 'Produit est ajouté au panier';

		$('#modal-lifting').modal('hide');
		$('#modal-torino').modal('hide');
		$('#modal-genova').modal('hide');
		$('#modal-japan').modal('hide');
		$('#modal-cks').modal('hide');
		$('#modal-shuttle').modal('hide');
		$('#modal-fms').modal('hide');
		$('#modal-bcs').modal('hide');
		$('#modal-cut').modal('hide');
		$('#modal-cut-compleet').modal('hide');
		new Noty({
			type: 'alert',
			layout: 'bottomRight',
			theme: 'sunset',
			text: message,
			timeout: 3000,
			progressBar: true,
			closeWith: ['click', 'button'],
			animation: {
				open: 'noty_effects_open',
				close: 'noty_effects_close'
			},
			queue: 'global',
			modal: false
		}).show();
	});

}

function round_decimal( $val, $decimals)
{
	$decimals = Math.pow(10,$decimals);
	$rounded = Math.round( $val * $decimals ) / $decimals;
	return $rounded;
}

function imageExists(image_url){
    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;
}

function getCylinderAmount(width){
	if( width < 135 ) {
		return 3;
	}
	else if( width < 190 ){
		return 4;
	}
	else if( width < 240 ){
		return 5;
	}
	else if( width < 315 ){
		return 6;
	}
	else if( width < 365 ){
		return 7;
	}
	else if( width < 400 ){
		return 8;
	}
	else if( width < 445 ){
		return 9;
	}
	else if( width < 490 ){
		return 10;
	}
	else if( width < 535 ){
		return 11;
	}

	return 12;
}

